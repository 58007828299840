var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('MoreInfo'),_c('h3',{staticClass:"text-center mb-3"},[_vm._v(_vm._s(_vm.officeName)+" "),_c('span',{staticClass:"font-weight-light"},[_vm._v("("+_vm._s(_vm.cityName)+")")])]),_c('v-card',{staticClass:"elevation-1 mx-7"},[_c('v-card-title',[_vm._v(" Sucursales Registradas "),_c('v-spacer'),_c('v-btn',{staticClass:"mx-auto",attrs:{"to":_vm.$route.params._id+'/create',"elevation":"2","color":"primary"}},[_vm._v("Nueva Sucursal")])],1),_c('v-text-field',{staticClass:"mx-4",attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.dataset,"search":_vm.search,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"items-per-page":5,"footer-props":{
                'items-per-page-text':'Filas por Página',
                'items-per-page-all-text': 'Todos'
            },"headerProps":{
                sortByText:'Ordenar por...'
            },"no-data-text":'No hay datos disponibles'},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item._id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item._id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }